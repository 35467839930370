export default {
  namespaced:true,
  state:() =>({
    
    agencia:{
      id:null,
      nombre:'',
      encargado_id:null,
      empleados:[],
      precios:{
        adulto:0,
        menor:0,
        infante:0
      },
      logotipo:null,
      telefono:null,
      email:null,
      sitio_web:null,
      direccion:'',
      lat:null,
      lng:null,
      ciudad_id:null,
      estado_id:null,
      bono_adulto:0,
      reservas_gratis:false,

    },

    agencias:[]

  }),

  getters:{
    draft:(state) => clone(state.agencia)
  },



  mutations:{

    clear(state){
      state.agencia = {
        id: null,
        nombre: "",
        encargado_id: null,
        empleados: [],
        precios: {
          adulto: 0,
          menor: 0,
          infante: 0,
        },
        logotipo: null,
        telefono: null,
        email: null,
        sitio_web: null,
        direccion: "",
        lat: null,
        lng: null,
        ciudad_id: null,
        estado_id: null,
        bono_adulto: 0,
        reservas_gratis: false,
      };
    },


    setAgencias(state,agencias){
      if(agencias){
        state.agencias = agencias
      }
    },


    setAgencia(state,agencia){
      if( agencia){
        state.agencia = agencia
      }
    }


  },


  actions:{

    getListSelect({commit}){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/agencias/list-select`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    fetch({commit},agencia_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/agencias/${agencia_id}/fetch-data`).then(({data}) => {

          commit('setAgencia',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/agencias/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchDataReporte({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/reportes/puntos-promocion/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    uploadLogotipo({commit},datos){

      return new Promise((resolve, reject) => {

        let formData = new FormData();
        formData.append('logotipo', datos.logotipo)
        formData.append('_method','PUT')
        axios.post(`/api/agencias/${datos.agencia_id}/upload-logo`,formData,{
          headers:{
            ContentType:'multipart/form-data'
          }
        }).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })

    },

    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/agencias/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          
          axios.post(`/api/agencias`,datos).then(({data}) => {
            
            if(data.result){
              commit('setAgencia',data.pick_up)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},agencia_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/agencias/${agencia_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    agregarEmpleado({state},promotor_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/agencias/${state.agencia.id}/agregar-promotor/${promotor_id}`).then(({data}) => resolve(data))
        .catch(e => reject(e))
      })
    },

     quitarPromotor({state},promotor_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/agencias/${state.agencia.id}/quitar-promotor/${promotor_id}`).then(({data}) => resolve(data))
        .catch(e => reject(e))
      })
    },

    fetchAgenciaPromotor({commit},promotor_id){

      return new Promise((resolve, reject) => {
        
        axios.get(`/api/agencias/promotor/${promotor_id}/fetch-data`).then(({data}) => {
          
          if(data.id){
            commit('setAgencia',data)
          }
          resolve(data)
        }).catch(e => reject(e))
        

      })
    },

    cargarCupones({commit},{agencia_id,tour_id}){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/agencias/${agencia_id}/cargar-cupones/tour/${tour_id}`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
        

      })
    },

    fetchDataPromotores({commit},filtro){
      return new Promise((resolve, reject) => {
        axios.post(`/api/agencias/promotores/fetch-data`,filtro).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    fetchDataOcupacion({commit},filtro){

      return new Promise((resolve, reject) => {
        
        axios.post(`/api/agencias/reporte/fetch-data-ocupacion`,filtro).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
      
    },

    fetchDataTickets({commit},filtro){
      return new Promise((resolve, reject) => {
        
        axios.post(`/api/agencias/reporte/fetch-data-tickets`,filtro).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    reporteGananciasBoletos({commit},filtro){
      return new Promise((resolve, reject) => {
          axios.post(`/api/agencias/reporte/fetch-data-ganancias-boletos`,filtro).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    reporteGananciasBoletosPorPromotor({commit},filtro){
      return new Promise((resolve, reject) => {
          axios.post(`/api/agencias/reporte/fetch-data-ganancias-boletos-por-promotor`,filtro).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    fetchDataCuponesAsignados({commit},datos){
      return new Promise((resolve, reject) => {
        
        axios.post(`/api/agencias/reporte/fetch-data-cupones-asignados`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
    },
    
    reporteIngresosFetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/agencias/reporte/fetch-data-ingresos`,datos).then(({data}) => resolve(data)).catch(e => reject(e));

      })
    },

    reporteEconomico({commit},datos){
      return new Promise((resolve, reject) => {
        
        axios.post(`/api/agencias/reporte/fetch-data-reporte-economico`,datos).then(({data}) => resolve(data)).catch(e => reject(e))

      })
    },

    reporteBonoPasajero({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/agencias/reporte/bono-pasajeros`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
        
      })
    }

  }

}