export default {
  namespaced:true,
  state:() =>({
    
    bitacora:{
      id:null,
      tipo:'',
      anterior:[],
      nuevo:[],
      color:null,
      causante:[]

    },

    bitacoras:[]

  }),


  mutations:{

    setBitacoras(state,bitacoras){
      if(bitacoras){
        state.bitacoras = bitacoras
      }
    },

    setBitacora(state,bitacora){

      if( bitacora){
        state.bitacora = bitacora
      }

    }

  },


  actions:{

    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/bitacoras/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


  }

}