import store from '@/store' 
export default{
  namespaced:true,

  state:() => ({
    negocio:{
      id:null,
      nombre:'',
      logotipo:null,
      activo:false,
      breve:'',
      descripcion:'',
      email:null,
      telefono:null,
      sitio_web:null,
      direccion:'',
      codigo_postal:null,
      lat:20.6565177,
      lng:-105.211358,
      ciudad_id:null,
      estado_id:null,
      usuario_id:null,
      ciudad:null,
      estado:null,
      usuario:null,
      facturar:false,
      datos_facturacion:null,
      horario_trabajo:null,
      dias_laborables:[],
      visitas:[]
    },


    empleado:{
      id:null,
      nombre:null,
      apellido:null,
      email:null,
      telefono:null,
      rol_id:null,
      permissions:[],
      permisos:[],
      rol:{
        permisos:[]
      },
      pivot:{
        cargo:null,
        usuario_id:null,
        negocio_id:null
      }
    },
    empleados:[],

    negocios:[],
    tablero:[
       {
        icon: 'fa-cash-register',
        color: 'light-primary',
        title: '0',
        subtitle: 'Total ventas (historico)',
        customClass: 'mb-2 mb-xl-0',
      },
      {
        icon: 'fa-money-check-dollar',
        color: 'light-info',
        title: '0$',
        subtitle: 'Total ventas (En el mes)',
        customClass: 'mb-2 mb-xl-0',
      },
      {
        icon: 'fa-people-roof',
        color: 'light-success',
        title: '0',
        subtitle: 'Total clientes recibidos (historico)',
        customClass: 'mb-2 mb-sm-0',
      },
      {
        icon: 'fa-divide',
        color: 'light-success',
        title: '0$',
        subtitle: 'Venta promedio por personas',
        customClass: '',
      },

  
    ]
  }),


  getters:{
    draft:(state) => clone(state.negocio)
  },

  mutations:{
    clear(state){
      state.negocio = {
          id:null,
          nombre:'',
          logotipo:null,
          activo:false,
          breve:'',
          descripcion:'',
          email:null,
          telefono:null,
          sitio_web:null,
          direccion:'',
          codigo_postal:null,
          lat:20.6565177,
          lng:-105.211358,
          ciudad_id:null,
          estado_id:null,
          usuario_id:null,
          ciudad:null,
          estado:null,
          usuario:null,
          facturar:false,
          datos_facturacion:null,// [nombre_fiscal,rfc,domicilio_fiscal,constancia,banco,ultimo_digitos_cuenta] 
          horario_trabajo:null,
          dias_laborables:[],
          visitas:[]

      }
    },

    clearEmpleado(state){
      state.empleado = {
        id:null,
        nombre:null,
        apellido:null,
        email:null,
        telefono:null,
        rol_id:null,
        permissions:[],
        permisos:[],
        rol:{
            permisos:[]
        },
        pivot:{
          cargo:null,
          usuario_id:null,
          negocio_id:null
        }
      }
    },


    setNegocio(state,negocio){
      if(negocio){
        state.negocio = negocio
      }
    },
    setResultados(state,resultado){
      state.tablero = resultado ?? state.tablero
    },

    setEmpleado(state,empleado){
      state.empleado = empleado
    }
  },

  actions:{

    fetch({commit},negocio_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/negocios/${negocio_id}/fetch-data`).then(({data}) => {
          commit('setNegocio',data)
          resolve(data)
        }).catch(e => reject(e))
      })

    },

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post('/api/negocios/fetch-data',datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      let formData = new FormData();

      Object.keys(datos).forEach(val => {
        formData.append(val,datos[val])
      })

      const headers = {
        headers:{
          ContentType:'multipart/form-data'
        }
      }

      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append('_method',"PUT");

          axios.post(`/api/negocios/${datos.id}`,formData,headers).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))

        }else{
           axios.post(`/api/negocios`,formData,headers).then(({data}) => {
            if(data.result){
              commit('setNegocio',data.negocio)
            }
            resolve(data)
          }).catch(e => reject(e))
        }

      })
    },


    eliminar({commit},negocio_id){
      return new Promise((resolve, reject) => {
          axios.delete(`/api/negocios/${negocio_id}`).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })
    },

    fetchDataComisions({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/negocios/comisiones/fetch-data`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    asignarComision({commit},datos){
      return new Promise((resolve, reject) => {
        
        axios.put(`/api/negocios/${datos.negocio_id}/add-comisions`,{'comisiones':[datos]}).then(({data}) => {
          resolve(data)
        }).catch(e => {
          reject(e)
        })

      })
    },

    eliminarComision({commit},{negocio_id,comision_id}){

      return new Promise((resolve, reject) => {

        axios.put(`/api/negocios/${negocio_id}/remove-comisions`,{comision_id}).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    cambiarStatus({commit},{activo,negocio_id}){
      return new Promise((resolve, reject) => {
        axios.put(`/api/negocios/${negocio_id}/cambiar-status`,{activo}).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    changeVisita({commit},datos){
      return new Promise((resolve,reject) => {
        
        axios.post(`/api/negocios/salida/change-visita`,datos).then(({data}) => resolve(data)).catch(e => reject(e));

      });

    },

    changeVenta({commit},datos){
      return new Promise((resolve,reject) => {
        
        axios.post(`/api/negocios/salida/change-venta`,datos).then(({data}) => resolve(data)).catch(e => reject(e));

      });
    },

    listSelect({commit}){
      return new Promise((resolve,reject) => {
        
        axios.get(`/api/negocios/get-list-select`).then(({data}) => resolve(data)).catch(e => reject(e))

      })
    },

    fetchEncargado({commit},negocio_id){
      
      return new Promise((resolve, reject) => {
        axios.get(`/api/negocios/${negocio_id}/fetch-encargado`).then(({data}) => resolve(data)).catch(e => reject(e))
      })

    },


    establecerEncargado({commit},datos){
      return new Promise((resolve, reject) => {
        axios.put(`/api/negocios/${datos.negocio_id}/establecer-encargado`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    diasAsistidos({commit},negocio_id){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/negocios/${negocio_id}/dias-asistidos`).then(({data}) => {
          resolve(data)
        }).catch( e => reject(e))

      })
    },

    resultadoDiarioSalida({commit},{salida_id,negocio_id}){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/salidas/${salida_id}/resultado-diario/negocio/${negocio_id}`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    resultadoSemanalNegocio({commit},datos){
      return new Promise((resolve, reject) => {
           axios.put(`/api/negocios/${datos.negocio_id}/resultado/semanal`,datos).then(({data}) => {
              resolve(data)
            }).catch(e => reject(e))
      })
    },

    guardarDatosFacturacion({commit},{negocio_id,datos}){
      return new Promise((resolve, reject) => {

        let formData = new FormData();
    
        Object.keys(datos).forEach(k => {
          formData.append(k,datos[k])
        })


        formData.append('_method',"PUT");

        axios.post(`/api/negocios/${negocio_id}/guardar/datos-facturacion`,formData,{
          headers:{
            ContentType:'multipart/form-data'
          }
        }).then(({data}) => {
          if(data.result){
            commit('setNegocio',data.negocio)
            store.commit('usuario/actualizarNegocioUsuario',data.negocio)
          }

          resolve(data)
        }).catch(e => reject(e))


      })
    },

    cargarTableroNegocio({state,commit},{negocio_id,filtro}){
      return new Promise((resolve, reject) => {
        
        axios.put(`/api/negocios/${negocio_id}/get/resultados-tablero`,{filtro}).then(({data}) => {
          commit('setResultados',data)
          resolve(data)
        }).catch(e => {
          reject(e)
        })

      })
    },

    cargarTourChart({commit},{negocio_id,filtro}){
      return new Promise((resolve, reject) => {
        
        axios.put(`/api/negocios/${negocio_id}/resultados/chart-tour`,{filtro}).then(({data}) => resolve(data)).catch(e => reject(e))

      })
    },
    cargarComisiones({comisiones},negocio_id) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/negocios/${negocio_id}/get/fetch-comisiones`).then(({data}) => resolve(data))
        .catch(e => reject(e))
      })
    },


    cargarVentaSalida({commit},{salida_id,negocio_id}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/negocios/${negocio_id}/venta/salida/${salida_id}`).then(({data}) => resolve(data)).catch(e => reject(e));
      })
    },

    fetchDataDeuda({commit},filtro){
      return new Promise((resolve, reject) => {
        axios.post(`/api/negocios/fetch-data-deuda`,filtro).then(({data}) => resolve(data)).catch(e => reject(e))

      })
    },
    
    fetchDataEmpleados({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/negocios/fetch-data-empleados`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
      });
    },
    fetchEmpleado({commit},empleado_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/negocios/empleados/${empleado_id}/fetch-data`).then(({data}) => {
          commit('setEmpleado',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardarEmpleado({commit},datos){
      return new Promise((resolve, reject) => {
        console.log(datos);
        if(datos.id){
          axios.put(`/api/negocios/empleados/${datos.id}`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post(`/api/negocios/empleados`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
        }
      })
    },

    eliminarEmpleado({commit},empleado_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/negocios/empleados/${empleado_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    }
  }

}